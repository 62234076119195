<script setup lang="ts">
import { DateTime } from 'luxon'

const now = DateTime.now()
const year = now.get('year')

const aepEnd = DateTime.fromISO(`${year}-12-07T00:00:00Z`)
const aepStart = DateTime.fromISO(`${year}-10-15T00:00:00Z`)
const duration = ref<Record<string, string>>()

onMounted(() => {
  useIntervalFn(
    () => {
      duration.value = _.mapValues(
        aepEnd
          .diffNow()
          .shiftTo('days', 'hours', 'minutes', 'seconds')
          .toObject(),
        (value) => value!.toFixed()
      )
    },
    500,
    {
      immediateCallback: true,
    }
  )
})
</script>

<template>
  <div
    v-if="now < aepEnd && now > aepStart"
    class="flex gap-2 bg-lime-600 text-white items-center justify-center py-2"
  >
    <div class="rounded-full bg-white p-1">
      <Icon i-heroicons-solid:calendar class="w-4 h-4 text-lime-600" />
    </div>
    <div class="flex items-center flex-col">
      <span class="font-bold">Annual Medicare Enrollment ends in:</span>
      <div>
        {{ duration?.days }} days | {{ duration?.hours }} hours |
        {{ duration?.minutes }} min | {{ duration?.seconds }} sec
      </div>
    </div>
  </div>
</template>
