<script setup lang="ts">
import { ref, computed } from 'vue'

import topEpc from '~/assets/ase-top-epc-icon.svg'
import medicarExpertize from '~/assets/ase-medicare-expertize.svg'
import speedometer from '~/assets/ase-speedometer.svg'
import customizedTerms from '~/assets/ase-customized-terms.svg'
import general1 from '~/assets/ase-general-1.png'
import general2 from '~/assets/ase-general-2.png'
import general3 from '~/assets/ase-general-3.png'
import general4 from '~/assets/ase-general-4.png'

useHead({
  title: `General Medicare - Turn your traffic into calls`,
})

analytics.page({
  name: 'General Medicare LP 1.0',
  category: 'LP',
  product: 'medicare',
})

const benefits = [
  {
    id: 'topEpc',
    title: 'Top EPCs',
    icon: topEpc,
    image: {
      src: general1,
      label: 'Happy General holding money',
    },
    description: `We have carriers and the largest brokers buying our calls. We
                have target caps and great EPCs. Set up a call with one of our
                account managers here.`,
    cta: {
      label: 'Improve your EPCs →',
      url: '#form',
    },
  },
  {
    id: 'topPerformingOffers',
    title: 'Top Performing Offers',
    icon: speedometer,
    image: {
      src: general2,
      label: 'Happy General raising the index finger to the air',
    },
    description: `Our landing pages were built by media buyers for high converting
                campaigns. The majority of our volume comes from our top landing
                pages that we have optimized.`,
    cta: {
      label: 'Get the best offers →',
      url: '#form',
    },
  },
  {
    id: 'medicareExpertize',
    title: 'Medicare Expertize',
    icon: medicarExpertize,
    image: {
      src: general3,
      label: 'General with a concerned face taking notes',
    },
    description: `Medicare has become an increasingly difficult vertical to scale.
                We have a team dedicated to scaling your volumes. Work with our
                Medicare Lead Gen Experts.`,
    cta: {
      label: 'Work with the Experts →',
      url: '#form',
    },
  },
  {
    id: 'customizedTerms',
    title: 'Customized Terms',
    icon: customizedTerms,
    image: {
      src: general4,
      label: 'General holding a pen and paper',
    },
    description: `We can work with partners on Net Share or CPC basis. And being
                powered by Karloon our reporting is unmatched. We can customize
                whatever terms they choose. We have also built a reputation of
                paying on time.`,
    cta: {
      label: 'Get Started →',
      url: '#form',
    },
  },
]

const formFields = [
  { id: 'firstName', label: 'First Name', type: 'text' },
  { id: 'lastName', label: 'Last Name', type: 'text' },
  { id: 'email', label: 'Email', type: 'email' },
  { id: 'companyName', label: 'Company Name', type: 'text' },
  { id: 'phone', label: 'Phone', type: 'tel' },
]

interface FormData {
  [k: string]: any
}

const formData = ref<FormData>({
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  phone: '',
})
const submitting = ref(false)
const submitted = ref(false)
const error = ref(false)

const submitForm = async () => {
  try {
    submitting.value = true
    const response = await fetch(
      'https://hooks.zapier.com/hooks/catch/16758180/22h7u4g/',
      {
        method: 'POST',
        body: JSON.stringify(formData.value),
      }
    )

    if (response.ok) {
      // Reset form fields after successful submission
      formData.value = {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        phone: '',
      }
      submitted.value = true
    } else {
      error.value = true
    }
  } catch (error) {
    console.error('Error submitting form:', error)
  } finally {
    submitting.value = false
  }
}
</script>

<template>
  <Layout header="simple5" footer="tos">
    <!-- hero -->
    <div class="bg-army-green text-white">
      <div class="container py-8 space-y-4 max-w-xl">
        <div class="text-3xl font-medium text-center">
          Leads are 💀
          <span class="text-yellow-300">Turn your traffic into calls</span>
        </div>

        <div class="text-center">
          You will not find a better click to call offer.
        </div>

        <div class="grid grid-cols-2 gap-2">
          <RouterLink
            v-for="benefit in benefits"
            :key="benefit.title"
            :to="`#${benefit.id}`"
            :class="[
              'rounded-md shadow bg-white p-3 space-y-1 hover:shadow-lg transition-shadow duration-300 hover:z-10',
              'active:outline active:outline-2 active:outline-yellow-300',
              'h-28 flex items-center justify-center flex-col',
            ]"
          >
            <img
              :class="benefit.icon"
              :src="benefit.icon"
              class="w-8 h-8 mx-auto text-army-green"
              :alt="`${benefit.title} Icon`"
            />
            <div class="text-center text-army-green"> {{ benefit.title }} </div>
          </RouterLink>
        </div>
      </div>
    </div>

    <!-- The Top Click to Call Partner in Medicare -->
    <div>
      <div class="container py-8 space-y-6 max-w-xl">
        <div class="text-3xl font-medium text-center">
          The Top Click to Call Partner in Medicare
        </div>

        <div class="space-y-20 flex flex-col">
          <div
            class="flex items-center gap-4 flex-col"
            v-for="benefit in benefits"
            :id="benefit.id"
          >
            <img
              :src="benefit.image.src"
              class="w-60 h-60 rounded-2xl"
              :alt="benefit.image.label"
            />
            <div>
              <div class="text-2xl font-semibold">{{ benefit.title }}</div>
              <div class="mt-2 text-gray-600" v-html="benefit.description">
              </div>
              <RouterLink
                :to="benefit.cta.url"
                class="text-army-green font-medium"
              >
                {{ benefit.cta.label }}
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Get Started form -->
    <div class="bg-army-green text-white" id="form">
      <div class="container py-8 max-w-xl">
        <div class="text-3xl font-medium text-center mb-6">
          Get Started with General Medicare
        </div>
        <div class="flex items-center justify-center" v-if="submitting">
          <Spinner :white="true" size="lg" />
        </div>
        <form @submit.prevent="submitForm" class="space-y-4" v-if="!submitted">
          <div
            v-for="field in formFields"
            :key="field.id"
            class="flex flex-col"
          >
            <label :for="field.id" class="mb-1">{{ field.label }}</label>
            <input
              :id="field.id"
              :type="field.type"
              :name="field.id"
              v-model="formData[field.id as any]"
              class="p-2 rounded text-black"
              required
            />
          </div>
          <button
            :disabled="submitting"
            type="submit"
            class="w-full bg-yellow-300 text-army-green font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
        </form>
        <div class="flex items-center justify-center" v-else>
          <span class="text-xl text-white">
            Thank You, we will be in touch!
          </span>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.bg-army-green {
  background-color: #4b5320;
}
.text-army-green {
  color: #4b5320;
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bridge"],
    "robots": "noindex"
  }
}
</route>
