<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'

const props = withDefaults(
  defineProps<{
    audioPath: string
    autoPlay?: boolean
    showPlay?: boolean
    showMute?: boolean
    isMuted?: boolean
    showProgress?: boolean
  }>(),
  {
    autoPlay: true,
    showPlay: false,
    isMuted: false,
    showMute: true,
  }
)

const emit = defineEmits<{
  (e: 'changeAutoPlay', value: boolean): void
  (e: 'changeMute', value: boolean): void
}>()

const audio = ref<HTMLAudioElement | null>(null)
const isPlaying = ref(false)
const duration = ref(0)
const currentTime = ref(0)
const progress = ref(0)

let interval: NodeJS.Timer | null = null

const startProgress = () => {
  if (progress.value === 100) {
    progress.value = 0
  }
  interval = setInterval(() => {
    progress.value += 1
    if (progress.value >= 100 && interval) {
      clearInterval(interval)
    }
  }, (duration.value * 1000) / 100)
}

const stopProgress = () => {
  if (interval) {
    clearInterval(interval)
  }
}

watch(
  () => props.audioPath,
  (newAudioPath, oldAudioPath) => {
    if (newAudioPath !== oldAudioPath) {
      if (audio.value) {
        audio.value.pause()
      }
      if (typeof window !== 'undefined') {
        audio.value = new Audio(newAudioPath)
        if (audio.value) {
          audio.value.onloadedmetadata = () => {
            duration.value = audio.value?.duration ?? 0
          }

          audio.value.ontimeupdate = () => {
            const max = Math.min(
              Math.ceil(audio.value?.currentTime ?? 0),
              Math.ceil(duration.value)
            )
            currentTime.value = max
          }

          audio.value.onended = () => {
            isPlaying.value = false
          }
          audio.value.load()
          isPlaying.value = false
          audio.value.muted = props.isMuted
          if (props.autoPlay && !props.isMuted) {
            audio.value
              .play()
              .then(() => {
                isPlaying.value = true
                startProgress()
              })
              .catch((error) => console.error('Error playing audio:', error))
          }
        }
      }
    }
  },
  { immediate: true }
)

onMounted(() => {
  if (props.autoPlay && audio.value) {
    audio.value.play()
    window.addEventListener('stop-audio', stopPlay)
  }

  window.addEventListener('blur', pauseAudio)
})

onUnmounted(() => {
  window.removeEventListener('blur', pauseAudio)
})

const stopPlay = () => {
  pauseAudio()
}

const pauseAudio = () => {
  audio.value?.pause()
  isPlaying.value = false
  stopProgress()
  emit('changeAutoPlay', isPlaying.value)
}

const togglePlayPause = () => {
  if (audio.value?.paused) {
    audio.value
      .play()
      .then(() => {
        isPlaying.value = true
        startProgress()

        emit('changeAutoPlay', isPlaying.value)
      })
      .catch((error) => console.error('Error playing audio:', error))
  } else {
    pauseAudio()
  }
}

function toggleMute() {
  if (audio.value) {
    audio.value.muted = !audio.value.muted
    emit('changeMute', audio.value.muted)
  }
}
</script>

<template>
  <div class="flex items-center space-x-2 p-1 rounded w-full min-w-[220px]">
    <button
      @click="togglePlayPause"
      class="p-2 bg-gray-200 rounded min-w-8 min-h-8"
      v-if="showPlay"
    >
      <img
        src="../assets/play-circle-outline.svg"
        v-if="!isPlaying"
        class="w-6 h-6 text-gray-700"
        alt="Play button"
      />
      <img
        src="../assets/pause-circle-outline.svg"
        v-else
        class="w-6 h-6 text-gray-700"
        alt="Pause button"
      />
    </button>
    <button
      @click="toggleMute"
      class="p-2 bg-gray-200 rounded min-w-8 min-h-8"
      v-if="showMute"
    >
      <img
        src="../assets/volume-off.svg"
        v-if="isMuted"
        class="w-6 h-6 text-gray-700"
        alt="Mute button"
      />
      <img
        src="../assets/volume-on.svg"
        v-else
        class="w-6 h-6 text-gray-700"
        alt="Unmute button"
      />
    </button>
    <div class="relative w-full h-4 bg-white rounded-full shadow-inner-md">
      <div
        :style="{ width: `${progress}%` }"
        class="h-full bg-green-400 rounded-full"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.loading-bar {
  transition: width 0.1s linear;
}
</style>
