<script setup lang="ts">
import {
  ChatHeadline,
  Content,
  SelectedAnswersEmit,
  Message,
  ChatCarrier,
} from '~/modules/chat-ad'

import emilyNewAvatar from '~/assets/emily-b008.png'
import ZipCode from '~/components/ZipCode.vue'

const matches = ref(false)

const loading = ref<boolean>(true)
const error = ref<boolean>(false)
const selectedAnswers = ref<SelectedAnswersEmit | null>(null)
const zipCode = ref<string | null>('')
const carriers = ref<ChatCarrier[]>([])

const afid = computed(() => {
  return typeof phoneConfig.value === 'object' && 'afid' in phoneConfig.value
    ? phoneConfig.value.afid
    : undefined
})

onMounted(async () => {
  matches.value = window.matchMedia('(prefers-color-scheme: dark)').matches
  if (matches.value) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  await until(() => ip.lookup).toBeTruthy()

  zipCode.value = ip.lookup?.postalCode || null
  try {
    const data = await getTransferPhoneNumbers(afid, zipCode.value)
    carriers.value = data
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
})

analytics.page({
  name: 'Post Call Chat Page',
  category: 'LP',
  product: 'medicare',
})

const chatHeadline: ChatHeadline = {
  image: emilyNewAvatar,
  title: 'Benefit Advisor',
  subtitle: 'Ask me about your Medicare Benefits!',
  advisorName: 'Emily',
}

const onSelectAnswer = async (
  nextStepId: string,
  selected: SelectedAnswersEmit,
  callback: (response: Message[]) => void
) => {
  selectedAnswers.value = {
    ...selectedAnswers.value,
    ...selected,
  }

  const messages = getMessageBlockById(nextStepId)
  return callback(messages)
}

const getMessageBlockById = (stepId: string): Message[] => {
  return content.value.messageBlocks?.[stepId] ?? null
}

const content = computed<Content>(() => {
  return {
    messageBlocks: {
      init: [
        {
          type: 'text',
          content: 'Welcome back 👋',
          from: 'emily',
        },

        {
          type: 'buttons',
          content: 'How did your call go?',
          from: 'emily',
          answers: [
            {
              text: 'Good 👍',
              answer: 'goodCall',
              event: { property: 'goodCall', value: true },
            },
            {
              text: 'Bad 👎',
              answer: 'badCall',
              event: { property: 'goodCall', value: false },
            },
          ],
          clicked: false,
        },
      ],
      goodCall: [
        {
          type: 'text',
          content: 'Good',
          from: 'user',
        },
        {
          type: 'text',
          content: 'Happy to hear that.',
          from: 'emily',
        },

        {
          type: 'buttons',
          content: 'Did you find what were looking for?',
          from: 'emily',
          answers: [
            {
              text: 'Yes 👍',
              answer: 'whatToDoNext',
              event: { property: 'foundWhatWasLookingFor', value: true },
            },
            {
              text: 'No 👎',
              answer: 'didntFindAfterGoodCall',
              event: { property: 'foundWhatWasLookingFor', value: false },
            },
          ],
          clicked: false,
        },
      ],
      badCall: [
        {
          type: 'text',
          content: selectedAnswers.value?.goodCall ? 'Good' : 'Bad',
          from: 'user',
        },
        {
          type: 'text',
          content: `I'm really sorry.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: 'what went wrong?',
          from: 'emily',
          answers: [
            {
              text: 'Bad experience',
              answer: 'whatToDoNext',
              event: { property: 'badExperience', value: true },
            },
            {
              text: `They didn't have what I wanted`,
              answer: 'whatToDoNext',
              event: { property: 'foundWhatWasLookingFor', value: false },
            },
          ],
          clicked: false,
        },
      ],
      didntFindAfterGoodCall: [
        {
          type: 'text',
          content: `No`,
          from: 'user',
        },
        {
          type: 'text',
          content: `I'm really sorry.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: 'What do you want to do next?',
          from: 'emily',
          answers: [
            {
              text: 'Get a second opinion',
              answer: 'secondOpinion',
              event: { property: 'getSecondOpinion', value: true },
            },
            {
              text: `Explore Plans Online`,
              answer: '',
              type: 'redirect',
              ctaUrl: '/ads',
              event: { property: 'explorePlansOnline', value: true },
            },
          ],
          clicked: false,
        },
      ],
      didntFind: [
        {
          type: 'text',
          content: `They didn't have what I wanted`,
          from: 'user',
        },
        {
          type: 'text',
          content: `I'm really sorry.`,
          from: 'emily',
        },
        {
          type: 'buttons',
          content: 'What went wrong?',
          from: 'emily',
          answers: [
            {
              text: 'Bad experience',
              answer: 'whatToDoNext',
            },
            {
              text: `They didn't have what I wanted`,
              answer: 'whatToDoNext',
            },
          ],
          clicked: false,
        },
      ],
      whatToDoNext: [
        selectedAnswers.value?.foundWhatWasLookingFor
          ? {
              type: 'text',
              content: 'Yes',
              from: 'user',
            }
          : {
              type: 'text',
              content: `They didn't have what I wanted`,
              from: 'user',
            },
        {
          type: 'buttons',
          content: 'What do you want to do next?',
          from: 'emily',
          answers: [
            {
              text: 'Get a second opinion',
              answer: 'secondOpinion',
              event: { property: 'getSecondOpinion', value: true },
            },
            {
              text: `Explore Plans Online`,
              answer: '',
              type: 'redirect',
              ctaUrl: '/ads',
              event: { property: 'explorePlansOnline', value: true },
            },
          ],
          clicked: false,
        },
      ],
      secondOpinion:
        carriers.value && carriers.value.length > 0
          ? [
              {
                type: 'text',
                content: 'Get a second opinion',
                from: 'user',
              },
              {
                type: 'carriers',
                content:
                  carriers.value && carriers.value.length > 0
                    ? 'Nice! You can call one of the following carriers:'
                    : `Sorry, I couldn't find any other carriers available now.`,
                from: 'emily',
                carriers: carriers.value,
              },
            ]
          : [
              {
                type: 'text',
                content: 'Get a second opinion',
                from: 'user',
              },
              {
                type: 'text',
                content: `Sorry, I couldn't find any other carriers available now.`,
                from: 'emily',
              },
              {
                type: 'text',
                content: `But I've found a few options that you can explore online`,
                from: 'emily',
              },
              {
                type: 'redirectButton',
                content: `Shop Plans`,
                from: 'emily',
                ctaUrl: '/ads',
              },
            ],
    },
    configuration: {
      id: 'postCallChat',
      initialBlockId: 'init',
    },
  } as Content
})
</script>

<template>
  <Layout
    :hide-header="true"
    :hide-footer="true"
    class="bg-gradient-to-b from-white to-gray-100 dark:from-[#1f1f1f] dark:to-[#2d2d2d]"
  >
    <span
      class="absolute text-xs top-2 right-2 border border-black p-1 rounded bg-white"
    >
      Ad
    </span>
    <template #banner>
      <CountdownHeader />
    </template>
    <Chat
      v-if="content && !loading && !error"
      :content="content"
      :headline="chatHeadline"
      @on-select-answer="onSelectAnswer"
      :get-message-block-by-id="getMessageBlockById"
    />
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bridge", "bh", "m10", "med10", "boomer"],
    "robots": "noindex"
  }
}
</route>
