<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
import allowanceCard4 from '../../assets/allowance-card-4.png'
import allowanceCard4Small from '../../assets/allowance-card-4-small.png'
import trunkAd from '../../assets/trunk-ad.png'
import trunkAdSmall from '../../assets/trunk-ad-small.png'

const loading = ref(true)
const source = ref(0)
const router = useRouter()

const isPaidUser = ref(true)

enterExperiment('otcAdImage')

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value) {
    router.push('/go-4-benefits')
    return
  }
})

const destination = computed(() => {
  let value =
    query.utm_source?.toLowerCase() === 'lgl' ||
    query.utm_source?.toLowerCase() === 'isabel' ||
    query.utm_source?.toLowerCase() === 'google'
      ? '/additional-benefits-grocery-2024/call-now'
      : '/additional-benefits-grocery-2024'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    if (exp.value?.otcAdDestination) {
      const variant = exp.value.otcAdDestination.toBenefits
        ? 'toBenefits'
        : 'original'

      params.set('exp', `otc-ad-destination-2023-10-20@${variant}`)
    }

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value = 0
  setTimeout(() => (loading.value = false), 500)
  source.value = stateConfig.value?.minOtcLtd
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

analytics.page({
  name: 'OTC Bridge 1',
  category: 'LP',
  product: 'medicare',
})

const showBackButtonModal = ref(false)

onMounted(() => {
  const forward = router.options.history.state.forward
  if (typeof forward === 'string' && forward.includes('grocery-2024')) {
    showBackButtonModal.value = true
  }
})
</script>
<template>
  <Layout
    :hide-header="true"
    footer="2024"
    class="bg-yellow-300 flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
  >
    <span
      class="absolute text-xs top-2 right-2 border border-black p-1 rounded"
    >
      Ad
    </span>
    <span
      v-if="loading"
      class="inset-0 flex items-center justify-center font-dm"
    >
      <Spinner />
    </span>
    <div
      v-if="!loading"
      :class="[
        'container h-full flex-grow min-h-[calc(100vh-320px)] flex mx-auto',
        'gap-6 py-6 text-xl sm:text-3xl font-bold flex flex-col text-white md:justify-evenly md:items-center',
      ]"
    >
      <div class="flex flex-col gap-4 md:flex-grow-0">
        <span
          class="text-4xl text-gray-800 font-bold inline-block font-dm flex gap-2 flex-col md:flex-row md:gap-8 md:justify-evenly md:items-center"
        >
          <span class="flex items-center"> Get</span>
          <span
            class="text-red text-5xl md:text-8xl flex gap-2 md:items-center md:justify-center"
          >
            $ {{ output.toFixed(0) }}
          </span>
          <span class="flex items-center"> or more annual OTC allowance</span>
        </span>
      </div>
      <div v-if="exp?.otcAdImage?.variant" class="image-wrapper-variant">
        <img
          :src="allowanceCard4"
          :srcset="`
            ${allowanceCard4Small}  480w,
            ${allowanceCard4}       1080w
          `"
          fetchpriority="high"
          width="504"
          height="331"
          sizes="60w"
          rel="preload"
        />
      </div>
      <div v-else class="image-wrapper">
        <img
          :src="trunkAd"
          :srcset="`
            ${trunkAdSmall}  480w,
            ${trunkAd}       1080w
          `"
          fetchpriority="high"
          sizes="60w"
          width="504"
          height="335"
          class="image"
          rel="preload"
        />
      </div>
      <div
        class="text-gray-600 text-sm font-sans text-center"
        v-if="!isPaidUser"
      >
        with some Medicare Advantage Plans
      </div>
      <div class="text-lg md:max-w-3xl md:w-full flex my-4 w-full">
        <Button
          :to="destination"
          class="font-sans"
          wide
          @click="
            analytics.track('learnMoreClicked', {
              source: $route.fullPath,
              destination,
            })
          "
        >
          <span> Learn More </span>
          <Icon i-heroicons-solid:arrow-right class="w-8 h-8 ml-4" />
        </Button>
      </div>
    </div>

    <Modal :open="showBackButtonModal" @close="showBackButtonModal = false">
      <ModalBackButton @close="showBackButtonModal = false" />
    </Modal>
  </Layout>
</template>

<style scoped>
.image-wrapper-variant {
  @apply w-full mx-auto border-4 border-white overflow-hidden md:w-[520px] md:h-[347px] md:border-8 rounded-xl;
}
.image-wrapper {
  @apply w-full mx-auto border-4 border-white overflow-hidden md:h-[480px] md:w-[520px] md:border-8;
  border-radius: 48px;
  @media screen and (min-width: 768px) {
    border-radius: 60px;
  }
}
.image {
  transform: scale(1.3) translateY(10px) translateX(-35px);

  @media screen and (min-width: 768px) {
    transform: scale(1.6) translateY(60px) translateX(-90px);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "benefits": ["otc"]
  }
}
</route>
