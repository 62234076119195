<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
const router = useRouter()
const loading = ref(true)
const source = ref(0)

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value) {
    router.push('/go-4-benefits')
  }
})

const destination = computed(() => {
  let value = isPaidUser.value
    ? '/additional-benefits-buyback-2024/call-now'
    : '/additional-benefits-buyback-2024'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

const stateConfig = computed(() => {
  const maxGiveback =
    states.find((state) => state.code === ip.lookup?.stateCode)?.maxGiveback ??
    70
  const minGiveback =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minGiveback ??
    4

  return {
    maxGiveback: Math.round(maxGiveback * 12),
    minGiveback: Math.round(minGiveback * 12),
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value = stateConfig.value?.minGiveback ?? 4
  setTimeout(() => (loading.value = false), 500)
  source.value = stateConfig.value?.maxGiveback
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeOutExpo,
})

analytics.page({
  name: 'Buyback Bridge 1',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout
    :hide-header="true"
    footer="2024"
    class="bg-[#508E2D] flex flex-col min-h-[100vh] p-4 gap-6 md:gap-12 font-dm items-center relative"
  >
    <span
      class="absolute text-xs text-gray-200 top-2 right-2 border border-gray-200 p-1 rounded"
    >
      Ad
    </span>
    <span
      v-if="loading"
      class="inset-0 flex items-center justify-center font-dm"
    >
      <Spinner />
    </span>
    <div
      v-if="!loading"
      :class="[
        'container h-full flex-grow min-h-[calc(100vh-320px)] flex',
        'gap-8 text-xl sm:text-3xl font-bold flex flex-col text-white md:items-center',
      ]"
    >
      <span
        class="text-3xl mt-10 md:text-7xl text-white font-bold inline-block font-dm flex gap-2 flex-col md:text-center md:gap-8 md:justify-evenly md:items-center uppercase"
      >
        <div class="flex flex-row items-center gap-4 md:gap-8 flex-grow">
          <span
            class="text-yellow-300 text-5xl md:text-7xl w-36 flex gap-2 md:items-center md:justify-center md:w-64"
          >
            $ {{ output.toFixed(0) }}
          </span>
          <span>/ yr</span>
        </div>
        or more of part b premium reduction
      </span>
      <div class="image-wrapper">
        <img src="../../assets/buyback-coin-jar-2.gif" class="image" />
      </div>
      <span class="text-sm -mt-6 w-dull text-center"
        >with some Medicare Advantage Plans</span
      >
      <div
        class="text-lg md:max-w-3xl md:w-full flex my-4 w-full justify-self-end"
      >
        <Button
          :to="destination"
          class="font-sans bg-yellow-300 !text-[#508E2D]"
          wide
          @click="
            analytics.track('learnMoreClicked', {
              source: $route.fullPath,
              destination,
            })
          "
        >
          <span> Learn More </span>
          <Icon i-heroicons-solid:arrow-right class="w-8 h-8 ml-4" />
        </Button>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.image-wrapper {
  @apply w-full md:w-[520px] md:h-[292px] mx-auto border-[#A5DA63] overflow-hidden md-auto md:border-8;
  @apply rounded-xl flex-grow-0;
}
.image {
  @apply: md:w-full;
  @media screen and (min-width: 768px) {
    transform: scale(1.08) translateY(9px);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "benefits": ["giveback"]
  }
}
</route>
