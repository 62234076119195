<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

interface Ad {
  url: string
  logo: string
  star: boolean
  title: string
  items: string[]
  button?: string
  verified?: boolean
}

const props = defineProps<{
  ads: Ad[]
}>()

const ads = computed(() => {
  return props.ads.map((ad) => {
    return {
      ...ad,
      hostname: new URL(ad.url).hostname,
    }
  })
})

const open = ref(false)

const handleClick = (ad: Ad) => {
  window.open(ad.url, '_blank')?.focus()
}

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = useIsMobile()
</script>

<template>
  <div class="space-y-4">
    <div
      v-for="ad in ads"
      class="bg-gray-100 hover:bg-white text-gray-900 rounded-md overflow-hidden shadow-md hover:shadow-lg p-4 transition ease-linear relative cursor-pointer md:flex md:items-center md:p-6"
      @click="handleClick(ad)"
    >
      <img
        :src="ad.logo"
        class="border-2 border-gray-200 w-36 h-18 object-contain mx-auto bg-white"
      />
      <div class="my-4 md:my-0 md:mx-6 md:flex-1">
        <div class="text-center md:text-left text-xl font-medium">
          {{ ad.title }}
        </div>
        <div
          class="flex items-center justify-center md:justify-start text-blue-400 mt-1"
        >
          <div> {{ ad.hostname }} </div>
          <Icon
            v-if="ad.verified"
            i-heroicons-solid:badge-check
            class="w-4 h-4 ml-1"
          />
        </div>

        <ul v-if="open || !isMobile" class="mt-4 pl-4">
          <li v-for="item in ad.items" class="text-sm text-gray-500 list-disc">
            {{ item }}
          </li>
        </ul>

        <div
          v-if="isMobile"
          class="flex items-center mt-4"
          @click.stop="open = !open"
        >
          <div class="h-px bg-gray-200 flex-1"></div>
          <div class="bg-gray-500 rounded-full p-1">
            <Icon v-if="open" i-heroicons-solid:chevron-up class="text-white" />
            <Icon v-else i-heroicons-solid:chevron-down class="text-white" />
          </div>
          <div class="h-px bg-gray-200 flex-1"></div>
        </div>
      </div>
      <Button :wide="isMobile" size="sm" variant="darkBlue">
        {{ ad.button || 'View My Quote' }}
      </Button>
      <template v-if="ad.star">
        <div
          class="absolute top-0 left-0 border-width-[50px_50px_0_0] border-transparent !border-t-yellow-300"
        >
        </div>
        <Icon
          i-heroicons-solid:star
          class="text-white absolute top-[5px] left-[5px]"
        />
      </template>
    </div>
  </div>
</template>
