<script setup lang="ts">
import seniorDental from '../../assets/senior-couple-dental.png'

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  // if (
  //   query.utm_source?.toLowerCase() === 'lgl' ||
  //   query.utm_source?.toLowerCase() === 'isabel' ||
  //   query.utm_source?.toLowerCase() === 'google'
  // ) {
  //   forceEnterExperiment('otc-ad-destination-2023-10-20', 'toBenefits')
  // }
})

const destination = computed(() => {
  let value = exp.value?.otcAdDestination?.toBenefits
    ? '/additional-benefits-grocery-2024/call'
    : '/additional-benefits-grocery-2024'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    if (exp.value?.otcAdDestination) {
      const variant = exp.value.otcAdDestination.toBenefits
        ? 'toBenefits'
        : 'original'

      params.set('exp', `otc-ad-destination-2023-10-20@${variant}`)
    }

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

analytics.page({
  name: 'My Dental x83',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout :hide-header="true" footer="2024">
    <div
      class="custom-gradient-bg min-h-[100vh] gap-8 md:gap-12 font-sans relative py-6"
    >
      <span
        class="absolute text-xs top-2 right-2 border border-black p-1 rounded"
      >
        Ad
      </span>

      <div
        :class="[
          'container px-6 mt-4 h-full flex-grow flex mx-auto',
          ' text-xl sm:text-3xl font-bold flex flex-col text-black ',
        ]"
      >
        <div class="text-xl gap-4 text-center z-10 flex flex-col items-center">
          <div class="bg-yellow-300 px-4 py-2 flex-grow-0">
            Low Copays On Routine
          </div>
          <div class="bg-[#63ffbf] px-4 py-2 flex-grow-0 uppercase">
            Dental Services
          </div>
          <div class="bg-yellow-300 px-4 py-2">
            Like Exams, Routine Cleanings
          </div>
          <div class="bg-[#63ffbf] px-4 py-2 flex-grow-0 uppercase">
            Fillings, crowns or
            <div class="text-3xl mt-2">dentures </div>
          </div>
        </div>
      </div>
      <div class="flex overflow-hidden justify-center mt-6">
        <img :src="seniorDental" class="w-full image md:w-[420px]" />
      </div>
      <div class="relative text-3xl bg-yellow-300 p-4 text-center -mt-12 z-10">
        <span>with some Medicare Advantage Plans</span>
      </div>
      <div
        class="text-lg md:max-w-3xl md:mx-auto px-4 sticky bottom-2 my-4 w-full justify-center z-20"
      >
        <Button
          :to="destination"
          class="font-sans"
          wide
          @click="
            analytics.track('learnMoreClicked', {
              source: $route.fullPath,
              destination: destination,
            })
          "
        >
          <span> Learn More </span>
          <Icon i-heroicons-solid:arrow-right class="w-8 h-8 ml-4" />
        </Button>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.custom-gradient-bg {
  background: rgb(236, 109, 247);
  background: linear-gradient(
    180deg,
    rgba(236, 109, 247, 1) 75%,
    rgba(253, 224, 71, 1) 77%,
    rgba(253, 224, 71, 1) 100%
  );
}
.image {
  transform: scale(1.6);
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "benefits": ["dental"]
  }
}
</route>
