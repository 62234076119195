<script setup lang="ts">
import Cleave from 'cleave.js'

defineProps<{
  buttonLabel?: string
  disabled?: boolean
}>()
const emit = defineEmits<{
  (e: 'submitZipCode', value: any): void
}>()

const errorZipCode = ref<boolean>(false)

const submitZip = () => {
  errorZipCode.value = false

  if (!errorMessage.value) {
    emit('submitZipCode', zipCode.value)
    return
  }
  errorZipCode.value = true
}

const mask = {
  blocks: [5],
  numericOnly: true,
}

const { value: zipCode, errorMessage } = useField('zipcode', 'zip')

onMounted(() => {
  if (mask) {
    new Cleave(`#zipcode`, {
      ...mask,
      onValueChanged: (event) => {
        zipCode.value = event.target.value
      },
    })
  }
})
</script>

<template>
  <div class="zipcode-input-wrapper">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      class="test h-7 w-7 text-gray-400 z-2 ml-2"
    >
      <path
        fill="currentColor"
        d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
      />
    </svg>
    <input
      type="number"
      id="zipcode"
      placeholder="ZIP code"
      :disabled="disabled"
      inputmode="numeric"
      :class="[
        'p-2 pl-12 text-black rounded-l-lg border-2 h-full border-red-500 focus:border-red-700 focus:outline-none',
        'disabled:text-gray-300 disabled:border-gray-300',
        errorZipCode && 'border-red-500',
      ]"
      v-model="zipCode"
    />

    <button
      @click="submitZip"
      :disabled="disabled"
      class="bg-red hover:bg-red-700 text-white font-bold py-2 px-4 rounded-r-lg border-2 border-red h-full disabled:bg-gray-300 disabled:border-gray-300"
    >
      {{ buttonLabel ?? 'Start' }}
    </button>
    <div v-if="errorZipCode"> &nbsp;</div>
    <div v-if="errorZipCode" class="text-sm font-sans text-red-600">
      It's not a valid zip code
    </div>
  </div>
</template>

<style scoped>
.zipcode-input-wrapper {
  @apply items-center grid font-normal;

  grid-template-columns: 0px 12rem auto;

  > input[type='number'] {
    outline: 0;
    outline-offset: 0;
  }
}
</style>
