<script setup lang="ts">
import bhLogo from '~/assets/logo-bh.png'
import m10Logo from '~/assets/logo-m10.png'

useHead({
  title: `Shop Medicare Plans Online - ${brand.name}`,
})

analytics.page({
  name: 'MediaAlpha Ads 1',
  category: 'LP',
  product: 'medicare',
})

const isMounted = useMounted()

const isMobile = useIsMobile()

if (isMobile.value) {
  enterExperiment('houseAds')
} else {
  enterExperiment('highlightAdsCard')
}

const domain = brand.id === 'm10' ? 'benefit-helpline.com' : 'medicare10.com'
const logo = brand.id === 'm10' ? bhLogo : m10Logo

const ads = [
  {
    url: `https://${domain}/go-4-benefits/call`,
    logo,
    star: true,
    verified: true,
    title: '2025 Medicare Advantage Plans',
    items: [
      'Find a plan that fits your needs',
      'Additional benefits available for those who qualify',
      'Check your zip code to get started',
      'Annual Enrollment Period ends 12/7',
    ],
    button: 'Shop Online',
  },
]

onMounted(() => {
  if (!isMobile.value && exp.value?.highlightAdsCard?.show) {
    let retryCount = 0
    const interval = setInterval(() => {
      const cards = document.querySelectorAll('.mav-partner')

      if (cards.length > 0 || retryCount >= 10) {
        clearInterval(interval)
      }

      let max = 0
      for (const card of cards) {
        const featuredIcon = card.querySelector('.mav-partner__featured-icon')

        if (featuredIcon) {
          featuredIcon.remove()
          card.setAttribute('style', 'padding-top: 48px;')
          const styleSheet =
            document.styleSheets[document.styleSheets.length - 1]
          if (styleSheet) {
            styleSheet.insertRule(
              '.mav-partner::before { content: none !important; }',
              styleSheet.cssRules.length
            )
          }
        }

        const div = document.createElement('div')
        div.setAttribute(
          'style',
          `position: absolute;
          top: 0;
          left: 0;
          width: 100%;  z-index: 10; border-radius: 0 0 12px 12px;
          background-color: #FCDF6B; padding: 4px; text-align: center;
          text-transform: uppercase; display: flex; align-items: center; justify-content: center; gap: 4px;
        `
        )
        div.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="12" height="12" viewBox="0 0 256 256" xml:space="preserve">
          <defs>
          </defs>
          <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
            <path d="M 47.755 3.765 l 11.525 23.353 c 0.448 0.907 1.313 1.535 2.314 1.681 l 25.772 3.745 c 2.52 0.366 3.527 3.463 1.703 5.241 L 70.42 55.962 c -0.724 0.706 -1.055 1.723 -0.884 2.72 l 4.402 25.667 c 0.431 2.51 -2.204 4.424 -4.458 3.239 L 46.43 75.47 c -0.895 -0.471 -1.965 -0.471 -2.86 0 L 20.519 87.588 c -2.254 1.185 -4.889 -0.729 -4.458 -3.239 l 4.402 -25.667 c 0.171 -0.997 -0.16 -2.014 -0.884 -2.72 L 0.931 37.784 c -1.824 -1.778 -0.817 -4.875 1.703 -5.241 l 25.772 -3.745 c 1.001 -0.145 1.866 -0.774 2.314 -1.681 L 42.245 3.765 C 43.372 1.481 46.628 1.481 47.755 3.765 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          </g>
        </svg> HIGHLY RECOMMENDED`

        card.appendChild(div)
        max++
        if (max >= 2) {
          break
        }
      }

      retryCount++
    }, 500)
  }
})
</script>

<template>
  <Layout
    :hide-header="isMobile || !isWorkingHours"
    footer="2024"
    class="container"
  >
    <template #banner>
      <Banner> New to Medicare? On Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Click 2 or More Options
      </div>
      <HouseAds v-if="exp?.houseAds?.top && isMobile" :ads="ads" />
      <div class="max-w-3xl mx-auto space-y-6">
        <MediaAlphaAd
          :placementId="
            query.utm_source?.toLowerCase() === 'ma'
              ? 'oLXPHoGj8cfc6Rk20iBxNYto4sr1mw'
              : 'xB51YFMkBb4D9ycHYv9YNkBUyjbbUA'
          "
          :user="{}"
        />
      </div>
      <HouseAds
        v-if="!exp?.houseAds?.top && isMounted && isMobile"
        :ads="ads"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "m10", "med10", "bridge"],
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0524176_C",
      "m10": "MULTI-PLAN_HLWEB0524175_C"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_HLWEB0524176_C",
      "m10": "MULTI-PLAN_HLWEB0524175_C"
    },
    "robots": "noindex"
  }
}
</route>
