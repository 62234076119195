<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
import { RouterLink, routerKey, useRoute } from 'vue-router'

const loading = ref(true)

const isPaidUser = ref(true)

const router = useRouter()

const show = ref(false)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }

  show.value = true
})

const isMobile = useIsMobile()

const destination = computed(() => {
  if (!isMobile.value) {
    return '/ads'
  }

  let value = `/additional-benefits-grocery-2024/${
    !isWorkingHours.value ? 'clicks' : 'call-now'
  }`

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    if (exp.value?.otcAdDestination) {
      const variant = exp.value.otcAdDestination.toBenefits
        ? 'toBenefits'
        : 'original'

      params.set('exp', `otc-ad-destination-2023-10-20@${variant}`)
    }

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

analytics.page({
  name: 'How Much Could You Get Monthly Page',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout v-if="show" :hide-header="true" footer="2024">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div
      class="bg-[#1F48C3] flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
    >
      <span
        class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
      >
        Ad
      </span>

      <div class="container md:max-w-1/2 max-w-full flex flex-col gap-6 mt-4">
        <div
          class="text-border text-white text-4xl md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center drop-shadow-2xl text-center"
        >
          How much could you get monthly?
        </div>
        <div class="mx-auto">
          <a
            :href="destination"
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination,
              })
            "
          >
            <img
              src="../../assets/question-block-red.gif"
              alt="Red block with a question mark"
          /></a>
        </div>
        <div
          class="text-border text-white text-lg md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center drop-shadow-2xl text-center"
        >
          For Healthy food, OTC products and Utility Bills
        </div>
        <div
          class="w-full flex gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow justify-center"
        >
          <a
            :href="destination"
            class="button bg-[#ff1014]"
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination,
              })
            "
          >
            <span
              class="flex flex-col justify-center items-center h-full !text-white font-bold text-lg"
            >
              TAP TO CHECK
            </span>
          </a>
        </div>
      </div>
    </div>

    <div
      class="bg-[#1F48C3] text-white container text-sm text-center mt-[-2px] p-2"
    >
      Find D-SNPs from Medicare Advantage carriers like Humana,
      UnitedHealthcare® and Aetna.
    </div>
  </Layout>
</template>

<style scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50px;
  padding: 8px 16px;
  min-width: 200px;
  -webkit-box-shadow: inset -7px -5px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset -7px -5px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: inset -7px -5px 5px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 1px 1px rgb(0 0 0 / 0.2);
  transition-property: transform, background-color;
  transition-duration: 0.4s, 0.4s;
  transition-timing-function: cubic-bezier(0.55, 1, 0.15, 1), linear;

  &:active {
    transform: scale(0.92);
  }
}
.custom-transition {
  transition: width 0.5s;
}
.text-border {
  color: white; /* Text color */
  text-shadow: -1px -1px 0 #000, /* Top-left shadow */ 1px -1px 0 #000,
    /* Top-right shadow */ -1px 1px 0 #000,
    /* Bottom-left shadow */ 1px 1px 0 #000; /* Bottom-right shadow */
}
.text-border-red {
  color: white;
  text-shadow: -1px -1px 0 #f55555, 1px -1px 0 #f55555, 1px 0 #f55555,
    1px 1px 0 #f55555;
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge", "m10"],
    "robots": "noindex",
    "smid": "MULTI-PLAN_HLDAD0324161_M"
  }
}
</route>
